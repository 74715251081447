.q-hero-section {
  &.login {
    margin-top: 300px;
    @media screen and (max-width: $break-mobile-large) {
      margin-top: 150px; }
    @media screen and (max-width: $break-mobile) {
      margin-top: 0; }
    .q-hero-image {
      max-height: 750px; } } }

.q-hero-section {
  display: flex;
  align-items: flex-end;
  height: auto;
  max-height: 100%;
  margin-bottom: 120px;
  margin-top: 150px;
  @media screen and (min-width: $break-mobile-large) {
    max-width: 1200px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  @media screen and (max-width: $break-mobile) {
    margin-bottom: 50px;
    margin-top: 0; }
  .q-hero-content {
    margin-top: 0;
    margin-bottom: 0;
    bottom: 0;
    position: relative; }
  &.q-small-box {
    margin-bottom: 50px;
    .q-blue-box {
      min-height: 450px;
      @media screen and (max-width: $break-mobile-large) {
        min-height: auto;
        margin-left: 0; } } }
  .q-hero-image {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    width: 100vw;
    max-height: 670px;
    overflow: hidden;
    img {
      width: 100%;
      @media screen and (min-width: $break-mobile) {
        margin-top: -140px; } }
    @media screen and (max-width: $break-mobile) {
      width: 100%;
      top: 0;
      position: relative; } }
  .q-hero-image-small {
    position: absolute;
    top: 120px;
    right: 0;
    width: 65vw;
    max-width: 1990px;
    @media screen and (min-width: $break-mobile-hd) {
      right: auto;
      margin-left: 200px;
      max-width: 1250px; }
    img {
      min-width: 100%;
      @media screen and (max-width: $break-mobile-large) {
        min-width: auto; }
      @media screen and (max-width: $break-mobile) {
        width: 100%; } }
    &.q-hero-image-left {
      left: 0;
      @media screen and (min-width: $break-mobile-hd) {
        left: auto;
        margin-left: 0;
        max-width: 950px; } }
    @media screen and (max-width: $break-mobile) {
      width: 100%;
      max-height: 350px;
      top: 0;
      position: relative; }
    &.q-max-h {
      max-height: 550px;
      overflow: hidden;
      @media screen and (max-width: $break-mobile) {
        img {
          width: 100%; } } } }
  .q-hero-btn-wrapper {
    margin-bottom: 60px;
    .q-btn {
      min-width: 40%;
      @media screen and (max-width: $break-mobile-large) {
        min-width: 50%; }
      @media screen and (max-width: $break-mobile) {
        min-width: 100%; } } }
  .q-blue-box {
    position: relative;
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    min-height: 575px;
    margin-left: -50px;
    background-color: rgba( $main, 85% );
    padding: 50px;
    color: $white;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    @media screen and (min-width: $break-mobile-large) {
        height: 100%; }
    @media screen and (max-width: $break-mobile) {
      min-height: auto; }
    @media screen and (max-width: $break-mobile-large) {
      margin-left: 0; }
    .q-blue-box-header {
      color: inherit;
      font-size: 2.425rem;
      @media screen and (max-width: $break-mobile-small) {
        font-size: 1.425rem; } }
    a {
      &:hover {
        color: $white; } }
    .q-blue-box-logo {
      margin-bottom: 80px;
      @media screen and (max-width: $break-mobile-large) {
        margin-bottom: 40px; }
      @media screen and (max-width: $break-mobile-small) {
        margin-bottom: 20px; }
      img {
        width: auto;
        height: 100%;
        max-height: 100px;
        @media screen and (max-width: $break-mobile-small) {
          max-height: 60px; } } } } }


/*
  img.parallax
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: auto
    z-index: -1
    transform: scale(1.0)
  */
