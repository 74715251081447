/* Allgemein */
.q-btn {
  position: relative;
  display: inline-block;
  font-weight: 400;
  text-transform: uppercase;
  color: $main;
  text-align: left;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: $white;
  border: 1px solid transparent;
  padding: 13.8px 20px 12.42px 20px;
  font-size: .75rem;
  line-height: 1;
  border-radius: 0;
  padding-left: 40px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  transition: all 0.15s ease-out;
  font-family: Averta-Bold;
  @media screen and (min-width: $break-mobile-large) {
    padding: 27.5px 40px 24.75px 40px;
    line-height: 1;
    border-radius: 0;
    padding-left: 65px;
    padding-right: 30px; }
  &:hover {
    color: $white;
    background-color: $main;
    text-decoration: none; }
  &:active {
    color: inherit !important;
    background-color: $main !important;
    border-color: $main !important; }
  &::before {
    position: absolute;
    left: 10px;
    content: "";
    font-family: icon-font;
    font-weight: 400;
    font-size: 180%;
    line-height: .5;
    color: inherit;
    @media screen and (min-width: $break-mobile-large) {
      left: 10px;
      font-size: 310%;
      line-height: 0.3; } }
  &.q-bg-transparent {
    &:hover {
      background-color: $main !important; } }
  &.q-btn-multilines {
    padding: 12.5px 40px 13.75px 60px;
    line-height: 1.4; }
  /* btn on Hover light */
  &.q-hover-light {
    &:hover {
      background-color: transparent;
      border-color: $white;
      color: $white; }
    &:active {
      background-color: $white !important;
      color: $main !important;
      border-color: $white !important; } }

  /* btn small */
  &.q-small {
    padding: 12.8px 20px 12.42px 40px;
    &::before {
      left: 10px;
      font-size: 180%;
      line-height: 0.4;
      @media screen and (max-width: $break-mobile) {
        font-size: 210%;
        line-height: 0.5; } }
    &.q-dropdown-icon {
      padding: 6px 20px 6px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  /* btn smaller */
  &.q-smaller {
    font-weight: bolder;
    text-transform: none;
    padding: 9.8px 15px 9.42px 15px;
    &::before {
      display: none; } } }

/* Primary */
.q-btn-primary {
  color: $white !important;
  background-color: $main;
  border-color: $main;
  &:hover {
    color: $main !important;
    background-color: $white;
    border-color: $main;
    text-decoration: none; }
  &:active {
    color: $white !important;
    background-color: $main !important;
    border-color: $main !important; } }

/* Secondary */
.q-btn-secondary {
  color: $main;
  border-color: $main;
  &:hover {
    color: $white;
    background-color: $main;
    border-color: $main; }
  &:active {
    color: $white !important;
    background-color: $main !important;
    border-color: $main !important; }
  &.q-btn-checked {
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: $break-mobile) {
      padding: 6.8px 15px 6.42px 15px; }
    &:after {
      display: inline-block;
      content: ' ';
      background-image: url('../../img/icons/check.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 20px;
      width: 20px; }
    &:hover {
      &:after {
        background-image: url('../../img/icons/check-light.svg'); } } }
  &.invers {
    display: inline-flex;
    color: $white !important;
    border-color: $white;
    background-color: transparent;
    &:hover {
      background-color: $white;
      color: $main !important; } } }

/* Link with arrow */
.q-btn-link {
  padding: 0 20px 0 40px;
  border: none;
  margin-bottom: 8px;
  line-height: 1.4;
  &::before {
    left: 10px;
    font-size: 180%;
    line-height: 0.7;
    transition: all 0.15s ease-out; }
  &:hover {
    background-color: inherit;
    color: $hover-link;
    text-decoration: none;
    &::before {
      left: 5px; } }
  &.q-back-link {
    padding: 0;
    display: flex;
    align-items: center;
    &::before {
      content: "";
      font-family: icon-font;
      font-weight: 400;
      font-size: 150%;
      line-height: .5;
      color: inherit;
      -moz-transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1);
      -o-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1) {
        transform: scale(-1, 1); } }
    &:hover {
      &::before {
        margin-right: 5px; } } } }


