@font-face {
  font-family: 'icon-font';
  src: url('../../fonts/busch-jaeger-icons.ttf')  format('truetype'); }

@font-face {
  font-family: 'Averta';
  font-weight: 400;
  src: url('../../fonts/Averta-Regular.woff')  format('woff'); }

@font-face {
  font-family: 'Averta-Regular';
  font-weight: 400;
  src: url('../../fonts/Averta-Regular.woff')  format('woff'); }

@font-face {
  font-family: 'Averta-Light';
  font-weight: 100;
  src: url('../../fonts/Averta-Light.woff')  format('woff'); }

@font-face {
  font-family: 'Averta-Bold';
  font-weight: 700;
  src: url('../../fonts/Averta-Bold.woff')  format('woff'); }

@charset "UTF-8";
