.q-actions {
  a {
    margin-left: 15px; }
  .q-action-icon {
    width: 20px;
    height: 20px; } }

.q-toggle {
  .btn {
    border-radius: 20px;
    &.off {
      .toggle-handle {
        background-color: $main; } }
    &.toggle {
      border: 2px solid $main; }
    &:focus, &:active {
      border-color: $main !important;
      background-color: $main !important; } }
  .toggle-on {
    background-color: $main; }
  .toggle-off {
    background-color: $white; }
  .toggle-handle {
    background-color: $white;
    width: 60px; }
  &.q-teaser-toggle {
    .btn {
      line-height: 0; }
    .toggle {
      min-width: 40px;
      min-height: 25px; }
    .toggle-handle {
      width: 40px; } } }


.q-icon-link {
  &:hover, &.active {
    svg {
      g {
        fill: $black !important; } } } }

.q-status {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  &.q-active {
    background-color: $active; }
  &.q-problem {
    background-color: $problem; }
  &.q-disabled {
    background-color: $disabled; } }

.rating {
  .star {
    font-size: 25px; } }

.q-graph-cont {
  .q-bar {
    height: 10px;
    width: 100%;
    margin: 0 auto 10px auto;
    line-height: 30px;
    font-size: 16px;
    color: white;
    padding: 0 0 0 10px;
    position: relative;
    &::before {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      height: 10px;
      top: 0;
      z-index: -2;
      background: $lightgrey; }
    &::after {
      content: '';
      background: $main;
      height: 10px;
      transition: 0.7s;
      display: block;
      width: 100%;
      max-width: 48%;
      -webkit-animation: bar-before 1 1.8s;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1; } } }

@-webkit-keyframes bar-before {
  0% {
    width: 0px; }
  100% {
    width: 100%; } }


