header#page-nav {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: $white;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 1000;
  .navbar-nav {
    width: 100%;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    .navbar-brand {
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      img {
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        transform: scale(1); } } }
  .navigation-meta-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    min-height: 34px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 0px;
    @media screen and (max-width: $break-mobile) {
      display: block;
      padding-bottom: 0;
      padding-top: 30px;
      border-bottom: $dots; }
    .navigation-meta-item {
      position: relative;
      display: flex;
      @media screen and (max-width: $break-mobile) {
        display: block;
        margin-bottom: 20px; }
      &.portalLogin-loggedout {
        @media screen and (max-width: $break-mobile) {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start; }
        .navigation-meta-link {
          background-color: $lightgrey;
          color: $main;
          padding-right: 20px;
          padding-left: 15px;
          transition: all 0.4s ease;
          &::before {
            content: "";
            display: block;
            background: url("../../img/icons/login.svg") no-repeat;
            background-size: contain;
            width: 17px;
            height: 17px;
            float: left;
            margin: 0 6px 0 0; } } }
      &.portalLogin {
        @media screen and (max-width: $break-mobile) {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start; }
        .navigation-meta-link {
          background-color: $lightgrey;
          color: $main;
          padding-right: 40px;
          padding-left: 15px;
          &::before {
            content: "";
            display: block;
            background: url("../../img/icons/login.svg") no-repeat;
            background-size: contain;
            width: 17px;
            height: 17px;
            float: left;
            margin: 0 6px 0 0; }
          &.loggedIn {
            @media screen and (max-width: $break-mobile) {
              display: flex;
              align-items: center; }
            &::after {
              position: absolute;
              right: 15px;
              top: 10px;
              content: "";
              font-family: icon-font;
              font-weight: 400;
              font-size: 20px;
              line-height: 1.2;
              color: inherit;
              @media screen and (max-width: $break-mobile) {
                position: relative;
                right: auto;
                top: 0; } } } } }
      .navigation-meta-link {
        padding: 12px 12px 12px 12px;
        color: $darkgrey;
        font-weight: 700;
        font-size: 12px;
        white-space: nowrap;
        text-transform: uppercase;
        line-height: 1.67;
        transition: all 0.4s ease;
        @media screen and (max-width: $break-mobile) {
          font-size: 14px; }
        &:hover {
          text-decoration: none;
          color: $darkgrey;
          background-color: $lightgrey; } }
      .navigation-meta-link--lang {
        padding-right: 40px;
        &::after {
          position: absolute;
          top: 10px;
          right: 15px;
          content: "";
          font-family: icon-font;
          font-weight: 400;
          font-size: 20px;
          line-height: 1.2;
          color: $darkgrey;
          @media screen and (max-width: $break-mobile) {
            position: relative;
            right: auto;
            top: 6px; } } }
      .navigation-meta-link--submenu {
        color: $white;
        background-color: $main;
        text-decoration: none;
        &:hover {
          color: $white;
          background-color: $main;
          text-decoration: underline; } } } }
  .header-content {
    margin-left: auto;
    margin-right: auto;
    .header-navigation {
      width: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      flex-direction: column;
      display: flex;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -webkit-box-pack: left;
      -webkit-justify-content: left;
      -ms-flex-pack: left;
      justify-content: left;
      @media screen and (max-width: $break-mobile) {
        display: none;
        &.show {
          display: block;
          max-height: 90vh;
          overflow-y: scroll; } }
      .nav-item {
        font-size: .875rem;
        .nav-link {
          display: block;
          padding: 23px 18px;
          color: $black;
          font-weight: 700;
          text-transform: uppercase;
          font-size: .85rem;
          border-bottom: 3px solid transparent;
          transition: all 0.4s ease;
          cursor: pointer;
          height: 100%;
          @media screen and (max-width: $break-mobile-large) {
            font-size: 12px; }
          @media screen and (max-width: $break-mobile) {
            font-size: 18px;
            font-weight: 400;
            line-height: 1.83;
            padding-bottom: 0; }
          &:hover {
            border-bottom: 3px solid $main; } }
        &.logged-out {
          background-image: url('/assets/img/icons/lock-icon.png');
          background-position: 0px 22px;
          background-repeat: no-repeat;
          padding-left: 8px; }
        &.back-link {
          .nav-link {
            font-weight: 100;
            text-transform: none; } }
        &.messages {
          .nav-link {
            font-weight: 400;
            text-transform: none;
            color: $main;
            border-bottom: none;
            &:hover {
              border-bottom: none;
              text-decoration: underline; }
            .counter {
              color: $white;
              background-color: $main;
              padding: 1px 5px; } }
          @media screen and (max-width: $break-mobile) {
            margin-left: 0 !important;
            margin-top: 10px;
            border-top: $dots;
            .nav-link {
              padding-top: 40px;
              padding-bottom: 40px;
              font-size: 18px; } } } }
      .navigation-main-item--search {
        cursor: pointer;
        position: relative;
        padding-right: 30px;
        .search-icon {
          border-bottom: none;
          &:hover {
              border-bottom: none; } }
        .search-icon {
          &::before {
            position: absolute;
            content: "";
            font-family: icon-font;
            font-weight: 400;
            font-size: 2.3125rem;
            line-height: 1.2125rem;
            color: $black; } } } }
    .navbar-toggler {
      border: none; }
    .disabled {
      display: block;
      padding: 23px 20px;
      color: $darkgrey;
      font-weight: 700;
      text-transform: uppercase;
      font-size: .85rem;
      @media screen and (max-width: $break-mobile-large) {
        font-size: 12px; }
      @media screen and (max-width: $break-mobile) {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.83;
        padding-bottom: 0; } } }

  /*
    &.q-nav-shrink
      @media screen and (min-width: $break-mobile)
        .logo
          height: 50px
          display: flex
          justify-content: center
          align-items: center
          img
            transform: scale(0.7)
            margin-top: -15px
        .navigation-main-list
          .nav-item
            .nav-link
              padding: 15px 18px !important
              font-size: 0.75rem
        .navigation-meta-list
          .navigation-meta-item
            .navigation-meta-link
              padding-top: 7px !important
              padding-bottom: 7px !important
              font-size: 11px
              &.navigation-meta-link--lang
                &::after
                  top: 5px
    */ }
